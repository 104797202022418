import React from 'react';
import './footer.css'; 
import { Typography, Image, Layout, Col, Row  } from 'antd';

const { Title } = Typography


const { Footer } = Layout;

const BottomFooter = () => {
  return (
  <Footer className="footer" >
    <Row justify="start" align="middle" >
      <Col sm={{span: 18}}  md={{span:18}}>
        <div className="footer-text-container">
          <span className="footer-text" >© {new Date().getFullYear()} Synthetic Acumen </span>
          <span className="footer-text" >Privacy Policy </span>
          <span className="footer-text" >Terms and Conditions </span>
        </div>
      </Col>
      <Col sm={{span: 6}}  md={{span:6}}>
        <div className="footer-logo-container" >
          <Image
            width={170}
            src="./assets/synthetic-acumen-logo.PNG"
          />
        </div>
      </Col>
    </Row>
  </Footer>

  );
};

export default BottomFooter;