import React from 'react';
import './header.css'; 
import { Typography, Image, Layout  } from 'antd';
import { ReactComponent as Logoimage  } from '../assets/research-goat-logo.svg';


const { Title } = Typography


const { Header } = Layout;

const TopHeader = () => {

  return (
  <Header className="header">
      <nav>
        {/* <Image
          width={195}
          preview={false}
          src={Logoimage}
        /> */}
        <Logoimage/>
        <ul>
          {/*<li><a href="#">Home</a></li>*/}
        </ul>
    </nav>
  </Header>

  );
};

export default TopHeader;