import { Button, Image } from "antd";
import { useEffect, useRef, useState } from "react";
import { httpPostUpload } from "../helpers/http.helper";



export const AudioRecorder = ({ onDone = null as any, onStart = null as any})=> {
    const mimeType = "audio/mp3";
    const [permission, setPermission] = useState(false);
    const mediaRecorder = useRef<any>(null);
    const [recordingStatus, setRecordingStatus] = useState<string>("inactive");
    const [stream, setStream] = useState<any>(null);
    const [audioChunks, setAudioChunks] = useState<any[]>([]);
    const [audio, setAudio] = useState<any>(null);
    const [audioFile, setAudioFile] = useState<File>();


    const [stopIcon, setStopIcon] = useState('stop-icon.png');
    const [microphoneIcon, setMicrophoneIcon] = useState('microphone.png');

    const startRecording = async () => {
        if ("MediaRecorder" in window) {
            try {
                const streamData = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: false,
                });
                setPermission(true);
                setStream(streamData);

                setRecordingStatus("recording");
                const media = new MediaRecorder(streamData, { type: mimeType } as any);
                mediaRecorder.current = media;
                mediaRecorder.current.start(1000);
                let localAudioChunks = [] as any[];
                mediaRecorder.current.ondataavailable = (event:any) => {
                    if (typeof event.data === "undefined") return;
                    if (event.data.size === 0) return;
                    localAudioChunks.push(event.data);
                };
                setAudioChunks(localAudioChunks);
                onStart && onStart();

            } catch (err:any) {
                alert(err.message);
            }
        } else {
            alert("The MediaRecorder API is not supported in your browser.");
        }
       
    };



    const stopRecording = () => {
        setRecordingStatus("inactive");
        mediaRecorder.current.stop();
        mediaRecorder.current.onstop = async () => {
            const audioBlob = new Blob(audioChunks, { type: mimeType });
            const audioUrl = URL.createObjectURL(audioBlob);
         
            const audioFile = new File([audioBlob], 'voice.mp3', { type: 'audio/mp3' });

           // const speech = await httpPostUpload(`voiceToText/upload`,[audioFile]);

        //    const speech = await VoiceToText(audioBlob);

            //console.log(audioUrl);
            //console.log(speech);
            onDone && onDone(audioFile);
            setAudio(audioUrl);
            setAudioFile(audioFile);
            setAudioChunks([]);
        };
    };

   
  const ButtonHoverHandler = async () => {
    setMicrophoneIcon('microphone-white.png');
    setStopIcon('stop-icon-white.png');
  };

  const ButtonOutHandler = async () => {
    setMicrophoneIcon('microphone.png');
    setStopIcon('stop-icon.png');
  };

    
  

    // useEffect(()=>{
    //     getMicrophonePermission();
    // }, []);

    return (
        <div className="record-audio-area">
            <div className="audio-controls">
                    {recordingStatus !== "recording" && (
                        <div onClick={startRecording} className="interview-buttons-long link-cursor purple-button"
                        onMouseOver={(e) => ButtonHoverHandler()}
                        onMouseOut={(e) => ButtonOutHandler()}>
                            <Image
                            preview={false}
                            className="icon"
                            height={18}
                            alt=""
                            src={"../assets/"+microphoneIcon} //"microphone.PNG"
                            /> 
                            <span className="icon-inner-text">Record Response</span>
                        </div>
                    )}

                {permission && recordingStatus === "recording" && (
                    <div onClick={stopRecording} className="interview-buttons-long link-cursor purple-button"
                    onMouseOver={(e) => ButtonHoverHandler()}
                    onMouseOut={(e) => ButtonOutHandler()}>
                        <Image
                        preview={false}
                        className="icon"
                        width={16}
                        height={16}
                        alt=""
                        src={"../assets/"+stopIcon} //"stop-icon.PNG"
                        /> 
                        <span className="icon-inner-text">Stop Recording</span>
                    </div>
                    )}

                {/* {audio && (
                    <div className="audio-container">
                        <audio src={audio} controls></audio>
                        <a download href={audio}>
                            Download Recording
                        </a>
                    </div>
                )} */}
            </div>
        </div>
    );
}
