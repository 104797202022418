import React, { useState, useEffect, useRef  } from 'react';
import { Button, Input, Col, Divider, Row, Card, Image, Space, Layout } from 'antd';
import './App.css';
import { httpPostFile, httpPost, httpPostUpload, httpPut, httpRawGet, httpGet, getRequest } from './helpers/http.helper';
//import { textToSpeech } from './helpers/elevenlabs.helper';
import { textToSpeech } from './helpers/openai.helper';
import  ReactAudioPlayer from 'react-audio-player';
import ScreenRecording from './components/videoRecorder';
import TopHeader from './components/header'; 
import BottomFooter from './components/footer'; 
import { useSearchParams } from "react-router-dom";
import  VideoRecorder  from './components/videoRecorder'; 
import { AudioRecorder } from './components/audioRecorder';
import { ReactComponent as AiImage  } from "./assets/ai-image.svg";
import { isSafari, BrowserView, MobileView, isBrowser, isMobile, isTablet } from "react-device-detect";
import { UnsupportedBrowser } from './Unsupported-browser';
import { UnsupportedDevice } from './Unsupported-device';
import ReactPlayer from 'react-player';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const { TextArea } = Input;

const { Content } = Layout;

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

const App = () => {
  //#region StatesVars
  const [loadingQuestion, setLoadingQuestion] = useState(false);
  const [aiAudioFile, setAIAudioFile] = useState<File>();
  const [interviewId , setInterviewId] = useState('');
  const [prcessignFiles , setPrcessignFiles] = useState('');
  const [id , setId] = useState('');
  const [projectId , setProjectId] = useState('');
  const [Language , setLanguage] = useState('English');


  const [interviewInProgress , setInterviewInProgress] = useState(false);
  const [videoUploaded , setVideoUploaded] = useState(false);
  const [startingState , setStartingState] = useState(false);

  const [topics, setTopics] = useState([]);
  const [topicsIndex, setTopicsIndex] = useState(0);
  const [topicImageUrl , setTopicImageUrl] = useState('');
  const [topicVideoUrl , setTopicVideoUrl] = useState('');
  const [requestNewTopic , setRequestNewTopic] = useState(false);
  const [aiPlaying, setAiPlaying] = useState(false);
  const [arriving, setArriving] = useState(false);
  const [processing, setProcessing] = useState(false);
  
  const [interviewText , setInterviewText] = useState('');
  const [isIntroduction , setIsIntroduction] = useState(true);
  const [showThankyouImg , setShowThankyouImg] = useState(false);
  const [closeOut , setCloseOut] = useState('');
  
  const [currentTimeDisplay , setCurrentTimeDisplay] = useState('00:00');
  const [outOfTime , setOutOfTime] = useState(false);
  const outOfTimeRef = useRef(outOfTime);

  const currentSecondsOnTopicRef = useRef(0);
  const currentSecondsRef = useRef(0);

  const [videoRecording , setVideoRecording] = useState('');
  const [startRecording, setStartRecording] = useState('');

  const [currentlyRecordingAnswer, setCurrentlyRecordingAnswer] = useState(false);

  const CurrentQuestion = useRef<string>('');

  const [pauseText, setPauseText] = useState('Pause');
  const [pauseTimeDisplay, setPauseTimeDisplay] = useState('20:00');
  const isPausedRef = useRef(false);

  const pauseIntervalRef = useRef<NodeJS.Timer>();
  const idleIntervalRef = useRef<NodeJS.Timer>();
  const countdownTimerIntervalRef = useRef<NodeJS.Timer>();
  const topicIntervalRef = useRef<NodeJS.Timer>();

  const [playIcon, setPlayIcon] = useState('play-icon.png');
  const [pauseIcon, setPauseIcon] = useState('pause-icon.png');

  const [aiIdle, setAiIdle] = useState(false);
  const aiIdleRef = useRef(aiIdle);

  const videoFileName = useRef('');
  
  const currentlyRecordingAnswerRef = useRef(currentlyRecordingAnswer);
  const aiPlayingRef = useRef(aiPlaying);
  const loadingQuestionRef = useRef(loadingQuestion);

  
  const [height, setHeight] = useState(0);
  const observedDiv = useRef<HTMLDivElement>(null);

  //#endregion
  //#region Initalization

  const goToLink = ()=> {
    document.location.href = 'https://researchgoat.com/thank-you/';
  }

  useEffect(() => {
    if (!observedDiv.current) {
      return;
    }
    
    const resizeObserver = new ResizeObserver(() => {
      if(observedDiv.current!.offsetHeight !== height) {
        setHeight(observedDiv.current!.offsetHeight);
      }
    });
    
    resizeObserver.observe(observedDiv.current);


    return function cleanup() {
      resizeObserver.disconnect();
    }
  },[observedDiv.current])

  useEffect( () => {
    const url = new URL(window.location.href);
    let participantId = url.searchParams.get('participantID');
    let urlProjectId = url.searchParams.get('projectID');
    let urlLanguage = url.searchParams.get('lang');

    if(participantId){
      setId(participantId);
    }

    if(urlProjectId){
      setProjectId(urlProjectId);
    }

    if(urlLanguage && urlLanguage.trim() != ""){
      setLanguage(urlLanguage);
    }

    setInterviewText("Welcome. Click to start the interview.");

    const getID = async (paramId:any, projectId:any ) => {
      await getInterviewId(paramId,projectId);
    };
    getID(urlProjectId,participantId);

  }, []);
 

  //#endregion
  //#region Timers 

  const pauseTimer = (seconds: number) => {

    let remainingSeconds = seconds;
    setPauseTimeDisplay('20:00');

    const thisPauseInterval = setInterval(() => {
        const minutesDisplay = Math.floor(remainingSeconds / 60);
        const secondsDisplay = remainingSeconds % 60;

        setPauseTimeDisplay(`${minutesDisplay}:${secondsDisplay < 10 ? '0' : ''}${secondsDisplay}`);
        
        remainingSeconds--;
   
        if (remainingSeconds < 0) {
            clearInterval(pauseIntervalRef.current);
            
            if(!currentlyRecordingAnswerRef.current) {
              handleEndInterview();
            }
            setPauseText("Pause");
        }
      
    }, 1000);

    pauseIntervalRef.current = thisPauseInterval;
}


const idleCountdownTimer = (seconds: number) => {

  let remainingSeconds = seconds;

  const thisIntervalIdle = setInterval(() => {
      remainingSeconds--;
      
      if (remainingSeconds < 0) {
          clearInterval(idleIntervalRef.current);
          handlePause();
      }
  }, 1000);

  idleIntervalRef.current = thisIntervalIdle;
}



  const topicCountdownTimer = (seconds: number) => {

    let remainingSeconds = seconds;
    let thisTopicInterval = setInterval(() => {
       
        remainingSeconds--;
        currentSecondsOnTopicRef.current = remainingSeconds;
        if (remainingSeconds < 0) {
            clearInterval(topicIntervalRef.current);
            setRequestNewTopic(true);
            //("Time for new topic!");
        }

    }, 1000);

    topicIntervalRef.current = thisTopicInterval
  }


  const countdownTimer = (seconds: number) => {

    let remainingSeconds = seconds;

    let thisCountdownInterval = setInterval( async () => {
        const minutesDisplay = Math.floor(remainingSeconds / 60);
        const secondsDisplay = remainingSeconds % 60;

        setCurrentTimeDisplay(`${minutesDisplay}:${secondsDisplay < 10 ? '0' : ''}${secondsDisplay}`);
        
        remainingSeconds--;
        currentSecondsRef.current = remainingSeconds;

        if (remainingSeconds <= 0 && !(currentlyRecordingAnswerRef.current || aiPlayingRef.current || aiIdleRef.current)) {
          clearInterval(countdownTimerIntervalRef.current);
          clearInterval(topicIntervalRef.current);
          clearInterval(idleIntervalRef.current);
          clearInterval(pauseIntervalRef.current);
          outOfTimeRef.current = true;
          setCurrentTimeDisplay("00:00");
          await handleEndInterview();
        }
        else if(remainingSeconds <= 0 ){
          remainingSeconds = 0;
          outOfTimeRef.current = true;
        }
      
    }, 1000);

    countdownTimerIntervalRef.current = thisCountdownInterval
  }//#endregion
 
  //#region StartInterview
  const startInterview = async () => {

    await httpGet("interview/start/"+interviewId);
 
 
  }

  const getInterviewId = async (idParam:any, projectId:any) => {

    let res = await httpGet("interview/configInterview/"+idParam+"/"+projectId);
    

    if(!res){
      setInterviewText("Error encountered please try again later.");
      setStartingState(false);
    }
    else
    {
      if(res && res.error && res.error == 'SO_MANY_ACTIVE_INTERVIEWS'){
        setInterviewText("Interviews are all currently in use. Please try again in 15 minutes");
        setStartingState(false);
      }
      else if(res && res.error){
        setInterviewText("Error: "+ res.error);
        setStartingState(false);
      }
      else if(res && !res.error){

      setStartingState(true);
      setInterviewId(res.id);
      currentSecondsRef.current = ~~(res.time_limit*60);
      //currentSecondsRef.current = 40;
      setTopics(res.topics);

      setTopicsIndex(0);
      setTopicImageUrl(res.topics[0]['image_url']);
      setTopicVideoUrl(res.topics[0]['video_url']);
      }
    }
  }

  const handleStartInterview = async () => {

    setArriving(true);
    setInterviewInProgress(true);
    setStartingState(false);
    setVideoRecording('start');
    setInterviewText("");
    await startInterview;
    countdownTimer(currentSecondsRef.current);

    await getFirstQuestion(0);

    
  }
  //#endregion
  
  //#region GetQuestions
  const getFirstQuestion = async (index:any) => {
    if(index>0){
      setLoadingQuestion(true);
      loadingQuestionRef.current = true;
    }

    topicCountdownTimer(topics[index]['time_limit']*60);

    let res = await httpGet("gpt/get-random-question/"+interviewId+"/"+index+"/"+Language);

    if(res && res.question){
      CurrentQuestion.current = res.question;
      await getTextToSpeech(res.question);
      setInterviewText(res.question.replaceAll('\\"', '\"'));

      setIsIntroduction(res.topic.startIntroduction);
      setLoadingQuestion(false);
      setArriving(false);
      loadingQuestionRef.current = false;
    }
    else{
      await handleEndInterviewWithError(res.error);
    }

  }


  const getNextQuestion = async (audioFileFromUser:File) => {
  //  let res = await httpGet("gpt/get-random-question/"+interviewId+"/"+index);
    setLoadingQuestion(true);
    loadingQuestionRef.current = true;
    //setAiPlaying(false);
    //aiPlayingRef.current = false
    setInterviewText("");
    setStartRecording('');
    let nextQuestionNow = "yes";

    if(outOfTimeRef.current){
      nextQuestionNow = "no";  
    }

    if(requestNewTopic){
      nextQuestionNow = "no";
      setRequestNewTopic(false);
    }

    let interviewObj = {
      interviewId: interviewId,
      id: id,
      answerText: "",
      audioLink: "",
      videoLink: videoFileName.current,
      duration: 0,
      index: topicsIndex,
      nextQuestion: nextQuestionNow,
      topic: '',
      aiAudio: "",
      time_limit: currentTimeDisplay
    };
    
    // let res = await httpPostUpload("gpt/continue-interactions", [ audioFileFromUser, aiAudioFile ], interviewObj);
    let res = await httpPostUpload("gpt/continue-interactions", [ audioFileFromUser ], interviewObj);
    setIsIntroduction(false);

   if(!outOfTimeRef.current){
      if(res != null){

        if(res && res.question) {
          setAiPlaying(true);
          aiPlayingRef.current = true
          
          //Got next question on  topic
          let question = res.question as string;
          let systemPrompt = res.systemPrompt as string;
          CurrentQuestion.current = question;
          await getTextToSpeech(question);
          question = question.replaceAll('\\"', '');
          setInterviewText(question.replaceAll('\\"', '\"'));

          if(!outOfTimeRef.current){
            setLoadingQuestion(false);
            loadingQuestionRef.current = false;
          }
        }
        else if(res.error)
        {
          await handleEndInterviewWithError(res.error);
        }
        else if(topicsIndex+1 >= topics.length)
        {
          //NO NEW TOPICS LEFT
          //END OF INTERVIEW
          await handleEndInterview();
        }
        else
        {
          //NEW TOPIC
          //need to get new question for new topic
          getFirstQuestion(topicsIndex+1);
          setTopicsIndex(topicsIndex+1);
          setTopicImageUrl(topics[topicsIndex+1]['image_url']);
          setTopicVideoUrl(topics[topicsIndex+1]['video_url']);
          await httpGet("interview/endTopic/"+interviewId);
        }
      }
    }


  }
  //#endregion

  //#region TextToSpeech
  const getTextToSpeech = async (text:any) => {
    const textObj = {
      transcriptText: text.replaceAll("\\n"," ")
    };

    try {
    
      const audioElement = await new Audio(await process.env.REACT_APP_EXTERNAL_API_URL + `gpt/TextToSpeechStream/?transcriptText=`+text.replaceAll("\\n"," ") );
      audioElement.onerror = ()=>{
        console.error("Audio Error: ", audioElement.error);
        throw new TypeError('Audio Error message');
      };
      audioElement.onended = ()=>{
        setAiPlaying(false);
        aiPlayingRef.current = false;
        setAiIdle(true);
        aiIdleRef.current = true;
        if(!outOfTimeRef.current && !isPausedRef.current && !loadingQuestionRef.current){
          idleCountdownTimer(20);
        }

      }
      setLoadingQuestion(false);
      loadingQuestionRef.current = false;
      setAiPlaying(true);
      aiPlayingRef.current = true;
      audioElement.playbackRate=1.03;
      audioElement.play();
    } catch (err) {
      console.log('Failed to play...' + err);
    }
  }

  //#endregion
  //#region EndInterview

  const handleEndInterviewWithError = async (message: any) => {
    setInterviewText(message);
    setAiIdle(false);
    aiIdleRef.current = false;
    setPrcessignFiles('Processing Interview...');
    clearInterval(idleIntervalRef.current);
    setProcessing(true);
    setLoadingQuestion(true);
    loadingQuestionRef.current = true;


    setInterviewInProgress(false);
    
    clearInterval(countdownTimerIntervalRef.current);
    clearInterval(topicIntervalRef.current);
    setVideoRecording('');


    await getTextToSpeech(message);
    setLoadingQuestion(true);
    loadingQuestionRef.current = true;
    if(videoUploaded){
      setLoadingQuestion(false);
      loadingQuestionRef.current = false;
      setProcessing(false)
    }
  }

  const handleEndInterview = async () => {
    setAiIdle(false);
    aiIdleRef.current = false;
    setPrcessignFiles('Processing Interview...');
    clearInterval(idleIntervalRef.current);
    setProcessing(true);
    setLoadingQuestion(true);
    loadingQuestionRef.current = true;
    setInterviewText("");
    let res = await httpGet("gpt/conclusion/"+interviewId+"/"+Language);
    setCloseOut(res.question);
    //setInterviewText(res.question.replaceAll('\\"', '\"').slice(1,-1));
    setInterviewText(res.question.replaceAll('\\"', '\"'));
    setInterviewInProgress(false);
    
    clearInterval(countdownTimerIntervalRef.current);
    clearInterval(topicIntervalRef.current);
    setVideoRecording('');

    await getTextToSpeech(res.question);
    setLoadingQuestion(true);
    loadingQuestionRef.current = true;
    if(videoUploaded){
      setLoadingQuestion(false);
      loadingQuestionRef.current = false;
      setProcessing(false)
    }
    setIsIntroduction(true);
    setShowThankyouImg(true);
  };


  const handleVideoUpload = async (videoLocation:any) => {
    const interviewObj = {
      interviewId: interviewId,
      videoLink: videoLocation
      //videoLink: process.env.REACT_APP_EXTERNAL_API_URL + "getVideo/" + videoLocation
    };
    //await httpPostUpload(`interview/finish`,[videoLocation] , interviewObj, "file");
    let res = await httpPost(`interview/finish`, interviewObj);
    await delay(5000);
    setLoadingQuestion(false);
    loadingQuestionRef.current = false;

    setVideoUploaded(true);
    setPrcessignFiles('');
  };

  //#endregion
  //#region Pausing

  const handlePause = () => {

    if(isPausedRef.current){
      //Set Resume
      isPausedRef.current = false;
      clearInterval(pauseIntervalRef.current);

      topicCountdownTimer(currentSecondsOnTopicRef.current);
      countdownTimer(currentSecondsRef.current);
      if(!aiPlayingRef.current && !loadingQuestionRef.current){
        idleCountdownTimer(20);
      }

      setPauseText("Pause");
      
    }
    else
    {
      //Set Paused
      isPausedRef.current = true;
      clearInterval(countdownTimerIntervalRef.current);
      clearInterval(topicIntervalRef.current);
      clearInterval(idleIntervalRef.current);
      pauseTimer((20*60));
      //pauseTimer(10);
      setPauseText("Resume");
    }
  }

  const pauseButtonHoverHandler = async () => {
    setPlayIcon('play-icon-white.png');
    setPauseIcon('pause-icon-white.png');
  };

  const pauseButtonOutHandler = async () => {
    setPlayIcon('play-icon.png');
    setPauseIcon('pause-icon.png');
  };

  //#endregion
  
  if(isMobile || isTablet) {
    return <UnsupportedDevice />
  }

  if(isSafari) {
    return <UnsupportedBrowser />
  }




  return (
    <div className="container" >
        <Layout >
          <TopHeader />
          <Content className="content" >
            <Row gutter={[16, 16]} justify="center" align="top" className="interview-border" >
              <Col sm={{span: 24}}  md={{span:24}} className="interview-column">
                <Row gutter={[16, 16]} justify="center" align="top">
                  <Col span={18} className='interview-body interview-center-line-left'  style={{"minHeight": height }}>
                    {/* <div className="center-image-area"> */}
                    {(startingState) && (
                      <div className='center-image-container' >
                        <Image
                          preview={false}
                          className="link-cursor"
                          onClick={handleStartInterview}
                          width={444}
                          alt="Start Button"
                          src="/assets/start_button.png"
                        /> 
                      </div>
                    )}        
                    
                    {(showThankyouImg) && (
                      <div className='center-image-container-end' >
                        <Image
                          preview={false}
                          className=""
                          width={444}
                          alt="Thank You"
                          src="/assets/thank_you_button.PNG"
                        /> 
                      </div>
                    )}         
                    {/* </div> */}


                    <div className={ 'interview-text' }> 
                    
                    {(aiPlaying || interviewInProgress || prcessignFiles) && (
                      <div>
                        {((topicImageUrl || topicVideoUrl) && !isIntroduction) ? (
                          (topicVideoUrl) ? (
                            <video width={500} controls src={process.env.REACT_APP_EXTERNAL_API_URL + "interview/getWPVideo/?videoUrl=" + encodeURI(topicVideoUrl)} />
                            
                          ):
                          (
                            
                          <Zoom>
                            <Image
                            preview={false}
                            className="topicImage"
                            src={ process.env.REACT_APP_EXTERNAL_API_URL + "interview/getWPImage/?imageUrl=" + topicImageUrl }
                          />
                          </Zoom>
                          )
                      ):(
                        (!showThankyouImg) && (
                          <div className="no-image-area"></div>
                        )
                      )}
                      </div>
                    )}
                    
  
                      {interviewText.split("\\n").map(line => <div key={line}>{line}&nbsp;</div>)}
                      <br/>
                      {/*
                      {(prcessignFiles && !(!interviewInProgress && videoUploaded && !loadingQuestion && !aiPlaying)) && (
                      <div className='flex-center'>
                        <div className="interview-buttons-long link-cursor purple-button">
                            {prcessignFiles}
                        </div>
                      </div>
                    )} */}

                     {(!interviewInProgress && videoUploaded && !loadingQuestion && !aiPlaying) && (
                      <div className='flex-center'>
                        <div onClick={goToLink} className="interview-buttons-long link-cursor purple-button">
                          Finish Interview
                        </div>
                      </div>
                    )}

                    {(interviewInProgress || currentlyRecordingAnswer) && (
                    <div >      
                      {(pauseText === "Resume") && (
                        
                        <div className="pause-timer-area purple-button">
                            <span>Paused: {pauseTimeDisplay}</span>
                        </div>
                      )}
                      {(!(pauseText === "Resume" || arriving || loadingQuestion || aiPlaying )) && (
                        <AudioRecorder 
                            onStart={()=> {
                              setAiIdle(false);
                              aiIdleRef.current = false;
                              setCurrentlyRecordingAnswer(true);
                              currentlyRecordingAnswerRef.current = true;
                              setStartRecording(`${new Date().getTime()}`);
                              clearInterval(idleIntervalRef.current);

                            }}
                            onDone={async (audio:any)=> {
                              setCurrentlyRecordingAnswer(false);
                              currentlyRecordingAnswerRef.current = false;
                              await getNextQuestion(audio);
                            }} 
                          />
                      )}
                    </div>
                    )}
                    </div>

                    
                   

                  </Col>
                  <Col span={6} className="interview-center-line-right" ref={observedDiv}>
                    <div className="center-image-area">

                    <div style={(interviewInProgress || processing) ? {display:'none'}:{display:'block'}} className="timer-area-container-inital">
                      <div className="timer-area-inital gray-button">
                        <span>00:00</span>
                      </div>
                      
                    </div>

                    {(arriving && !processing) && (
                      <div className={'center-image-container-static'}>
                          {/* Arriving */}
                          <Image
                            preview={false}
                            width={300}
                            alt="AI Animation"
                            src="../assets/ai-animations/GIF_Animation_ARRIVING_TRANSPARENT.gif"
                          />  
                        </div>
                      )}
                      {(aiPlaying && !processing) && (
                      <div className={'center-image-container-static'}>
                          {/* AI Talking */}
                          <Image
                            preview={false}
                            width={300}
                            alt="AI Animation"
                            src="../assets/ai-animations/GIF_Animation_TALK_TRANSPARENT.gif"
                          />  
                        </div>
                      )}
                      {(!(aiPlaying || processing) && loadingQuestion) && (
                      <div className={'center-image-container-static'}>
                        {/* AI Thinking */}
                        <Image
                          preview={false}
                          width={300}
                          alt="AI Animation"
                          src="../assets/ai-animations/GIF_Animation_THINKING_TRANSPARENT.gif"
                        />  

                      </div>
                       )}
                      {!(aiPlaying || loadingQuestion || processing) && startRecording && (
                      <div className={'center-image-container-static'}>
                          {/* AI Listening */}
                          <Image
                          preview={false}
                          width={300}
                          alt="AI Animation"
                          src="../assets/ai-animations/GIF_Animation_LISTEN_TRANSPARENT.gif"
                        />  

                      </div>
                       )}
                      {(processing && !videoUploaded) && (
                      <div className={'center-image-container-static'}>
                          {/* AI Processing */}
                          <Image
                            preview={false}
                            width={300}
                            alt="AI Animation"
                            src="../assets/ai-animations/GIF_Animation_PROCESSING_TRANSPARENT.gif"
                          />  
                        </div>
                      )}
                       {((!startingState && !(processing || arriving || aiPlaying || loadingQuestion || startRecording)) && (interviewInProgress))   && (
                      <div className={ 'center-image-container-static'}>
                        {/* AI idle */}
                        <div className={'animation-svg'}>
                          <AiImage/>
                        </div>
                        
                      </div>
                      )}

                    </div>
                    <div style={interviewInProgress ? {visibility:'visible'}:{visibility:'hidden'}} className="timer-area-container">
                      <div className="video-area">
                        <VideoRecorder start={videoRecording} onDone={async (videoLocation:any)=> {
                            await httpGet("interview/end/"+interviewId);
                            await handleVideoUpload(videoLocation);
                          }} 
                          onStart={async (videoLocation:any)=> {
                            console.log("onStart: ", videoLocation);
                            videoFileName.current = videoLocation;
                          }}
                          />
                      </div>
                      <div className="timer-area-main">
                        <div className="timer-area gray-button">
                          <span>{currentTimeDisplay}</span>
                        </div>
                      </div>
                      <div className="timer-area-main">
                        <div style={(!startingState && !(processing || arriving || aiPlaying || loadingQuestion || startRecording)) && (interviewInProgress) ? {visibility:'visible'}:{visibility:'hidden'}}  className="pause-button-area interview-buttons link-cursor purple-button" onClick={handlePause}
                        onMouseOver={(e) => pauseButtonHoverHandler()}
                        onMouseOut={(e) => pauseButtonOutHandler()}>
                        {(pauseText !== "Resume") && (
                          <>
                          <Image
                            preview={false}
                            className="icon"
                            width={14}
                            alt=""
                            src={"/assets/"+pauseIcon} //"pause-icon.PNG"
                          /> 
                          <span className="icon-inner-text">Pause</span>
                          </>
                        )}
                        {(pauseText === "Resume") && (
                          <>
                            <Image
                            preview={false}
                            className="icon"
                            width={16}
                            alt=""
                            src={"/assets/"+playIcon} //"play-icon.PNG"
                          /> 
                          <span className="icon-inner-text">Resume</span>
                          </>
                        )}
                        </div>
                      </div>
                      
                    </div>
                    
                  </Col>
                </Row>
               

                
              </Col>
            </Row>
            <Row className='content-space'>
              <Col span={18} >
                <span className="blue-header">How it Works ? </span>
              </Col>
            </Row>
            <Row justify="center" gutter={[16, 16]}align="top" className='content-space steps-row'>
              <Col sm={{span: 8}}  md={{span:8}} lg={{span:4}} >
                <div className="step-container">
                  <span className="step-text">1. Press ‘Record’ to begin your response.</span>
                </div>
              </Col>
              <Col sm={{span: 8}}  md={{span:8}} lg={{span:4}} >
                <div className="step-container">
                  <span className="step-text">2. Press ‘Stop’ to submit your answer.</span>
                </div>
              </Col>
              <Col sm={{span: 8}}  md={{span:8}} lg={{span:4}} >
                <div className="step-container">
                  <span className="step-text">3. Use ‘Pause’ for short breaks, but return within 20 minutes to avoid restarting.</span>
                </div>
              </Col>
              <Col sm={{span: 8}}  md={{span:8}} lg={{span:4}} >
                <div className="step-container">
                  <span className="step-text">4. Ask the AI for clarification if you don’t understand something.</span>
                </div>
              </Col>
              <Col sm={{span: 8}}  md={{span:8}} lg={{span:4}} >
                <div className="step-container">
                  <span className="step-text">5. It’s okay to say “I don’t know” to unknown questions.</span>
                </div>
              </Col>
              <Col sm={{span: 8}}  md={{span:8}} lg={{span:4}} >
                <div className="step-container">
                  <span className="step-text">6. Contact your recruiter if technical issues arise.</span>
                </div>
              </Col>
            </Row>
          </Content>
          <BottomFooter/>
        </Layout>
    </div>
  );
};

export default App;
