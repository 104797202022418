import { useState, useRef, useEffect } from "react";
import { httpPostFile, httpPost, httpPostUpload, httpPut, httpRawGet, httpGet, getRequest } from '../helpers/http.helper';
import {v4 as uuidv4} from "uuid";
const AWS = require('aws-sdk');
export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

const VideoRecorder = ({ start = '', onDone = null as any , onStart = null as any }) => {
    const [permission, setPermission] = useState(false);
    const mediaRecorder = useRef<any>(null);
    const [recordingStatus, setRecordingStatus] = useState<string>("inactive");
    const [stream, setStream] = useState<any>(null);
    const [videoChunks, setVideoChunks] = useState<any[]>([]);

    const ChunkParts = useRef<any[]>([]);
    const Parts: any[] = [];
    let multipartMap = { Parts };
    const isVideoRecording  = useRef(false);
    let videoUploading = false;
    const allVideoUploaded = useRef(false);
    const videoFileName = useRef('');

    const mimeType = "video/mp4";
    let uploadId:any, keyName:any, partNumber:any = 0;
    //Generate the file name to upload. For the simplicity we're going to use the current date.
    const s3Key = `interview-video-${uuidv4()}.mp4`;
    let localVideoChunks = useRef<any[]>([]);
    const localChunkDataAvailable = useRef(false);

    const VideoPartNumber =  useRef(0);;
    
    const startRecording = async () => {
        onStart(s3Key);

        const params = {
            key: s3Key
        };
        const MPUCreated = await httpPost(`interview/createMultiPartUpload`, params);
        if (MPUCreated) {
            uploadId = MPUCreated.UploadId;
            keyName = MPUCreated.Key;
            videoFileName.current = MPUCreated.Key;
        } else {
            console.error('Error uploading to S3', MPUCreated);
        }

        setRecordingStatus("recording");
        isVideoRecording.current = true;
        const media = new MediaRecorder(stream, { type: mimeType, audioBitsPerSecond: 100000, videoBitsPerSecond: 1000000 } as any);
        
        mediaRecorder.current = media;
        mediaRecorder.current.start(30000);
        
        mediaRecorder.current.ondataavailable = (event:any) => {
            if (typeof event.data === "undefined") return;
            if (event.data.size === 0) return;
            
            if ((event.data.size / 1024)/1024 <= 5){
                localVideoChunks.current.push(event.data);
                localChunkDataAvailable.current = true
            }else{
                if(localChunkDataAvailable.current){
                    localVideoChunks.current.push(event.data);
                }
            }

            if(localChunkDataAvailable.current){
                const locVideoBlob = new Blob(localVideoChunks.current, { type: mimeType });
                if((locVideoBlob.size / 1024)/1024 > 5){
                    ChunkParts.current[VideoPartNumber.current] = locVideoBlob;
                    if(VideoPartNumber.current == 0){
                      handleDataAvailable();
                    }
                    VideoPartNumber.current++;

                    localVideoChunks.current = [];
                    localChunkDataAvailable.current = false;
                }
            }else{
                ChunkParts.current[VideoPartNumber.current] = event.data;
                if(VideoPartNumber.current == 0){
                    handleDataAvailable();
                }
                VideoPartNumber.current++;
            }
        };
    };

    const handleDataAvailable = async() => {
        while((isVideoRecording.current || partNumber < VideoPartNumber.current) && !videoUploading){
            if (ChunkParts.current[partNumber] && ChunkParts.current[partNumber] !== 'undefined'){
                if(ChunkParts.current[partNumber].size > 0) {
                    videoUploading = true;
                    let ChunkVideoToUpload = ChunkParts.current[partNumber];
                    await partUploadToS3(ChunkVideoToUpload);
                }
            }
            await delay(10000);
        }
        
        if(!isVideoRecording.current && partNumber == VideoPartNumber.current){
            await completeUpload();
        }
        
    };
 
    const partUploadToS3 = async(data:any) => {
        const params = {
            key: s3Key,
            partNumber: partNumber + 1,
            uploadId: uploadId,
        };

        try {
            const res = await httpPostUpload("interview/partUploadToS3", [data], params,  "file");
            if(res){
                multipartMap.Parts[partNumber] = {
                    ETag: res.ETag,
                    PartNumber: Number(partNumber + 1)
                    };
                partNumber++;
                videoUploading = false;
            }else{
                console.error('Error uploading to S3');
            }
            
        } catch (error) {
            console.error('Error uploading to S3', error);
        }
    
    };

    const completeUpload = async() => {
        const completeParams = {
            key: s3Key,
            multipartMap:multipartMap,
            uploadId: uploadId
        };
      
        try {
            const res = await httpPost(`interview/completeUpload`, completeParams);
            allVideoUploaded.current = true;
        } catch (error) {
            console.error('Error completing upload:', error);
        }
    };

    
    const stopRecording = async () => {
        setPermission(false);
        setRecordingStatus("inactive");
        mediaRecorder.current.stop();

        mediaRecorder.current.onstop = async () => {
            isVideoRecording.current = false;
            
            if(localChunkDataAvailable.current){
                const locVideoBlob = new Blob(localVideoChunks.current, { type: mimeType });
                ChunkParts.current[VideoPartNumber.current] = locVideoBlob;
                VideoPartNumber.current++;
            }

            while(!allVideoUploaded.current){
                await delay(5000);
            }

            if(allVideoUploaded.current){
                onDone(videoFileName.current);
            }
            
        };

    };

    const getCameraPermission = async () => {
        if ("MediaRecorder" in window) {

            try {

                const streamData = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video:{
                        width:{
                            min:320,
                            max:320
                        },
                        height:{
                            min:240,
                            max:240,
                        },
                    },
                });

                setPermission(true);

                setStream(streamData);

                const video = document.querySelector('video') || {} as any;

                video.srcObject = streamData;

            } catch (err:any) {
                const streamData = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video:{
                        width:{
                            min:640,
                            max:640
                        },
                        height:{
                            min:480,
                            max:480,
                        },
                    },
                });

                setPermission(true);

                setStream(streamData);

                const video = document.querySelector('video') || {} as any;

                video.srcObject = streamData;
            }

        } else {
            alert("The MediaRecorder API is not supported in your browser.");
        }
    };

    useEffect(()=>{
        if(stream) {
            startRecording();
        }
    }, [stream]);
    
    useEffect(()=>{
        if(start) {
            setVideoChunks([]);
            getCameraPermission();
        } else if(recordingStatus === 'recording') {
            stopRecording();
            setStream(null);
        }
    }, [start]);


    if(!start) {
        return null;
    }

    return (
        <video width={175} id="video" autoPlay={true} muted/>
    );

};

export default VideoRecorder;