
const header = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
};

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const httpPostFile = async (url: string, body: any) : Promise<any> => {
    //console.log(JSON.stringify(body));

    let responseJson;

    let maxRetries = 1;
    let retries = 0;

    while (retries < maxRetries) {

        const post = await fetch(`${process.env.REACT_APP_EXTERNAL_API_URL}${url}`, {
            method: "POST",
            headers: {
                "responseType": "blob",
                "Accept": "application/force-download",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(body),
        }).then(async (response) => {
            if(response.ok){
                responseJson = await response.body;
                retries = maxRetries;
            }
            else
            {
                throw new Error("Error: "+response.status);
            }
        }).catch(async (error) => { // Error handling here
            console.error(`Error making POST request: ${error}`);

            // Implement a delay here if needed.

            if (retries === maxRetries) {
            //throw new Error(`Max retries (${maxRetries}) reached. Unable to complete the request.`);
            }
            await delay(2000);
        });
        // Increment the retry count
        retries++; 
        

    }

    return responseJson;

}


export const httpPost = async (url: string, body: any) : Promise<any> => {
    //console.log(JSON.stringify(body));

    let responseJson;

    let maxRetries = 1;
    let retries = 0;

    while (retries < maxRetries) {

        const post = await fetch(`${process.env.REACT_APP_EXTERNAL_API_URL}${url}`, {
            method: "POST",
            headers: {
            ...header,

        },
        body: JSON.stringify(body),
        }).then(async (response) => {
            if(response.ok){
                responseJson = await response.json();
                retries = maxRetries;
            }
            else
            {
                throw new Error("Error: "+response.status);
            }
        }).catch(async (error) => { // Error handling here
            console.error(`Error making POST request: ${error}`);

            // Implement a delay here if needed.

            if (retries === maxRetries) {
            //throw new Error(`Max retries (${maxRetries}) reached. Unable to complete the request.`);
            }
            await delay(2000);
        });
        // Increment the retry count
        retries++; 
        

    }

    return responseJson;

}


export const httpPostUpload = async (url: string, files: any[], extra?: any, filename = "files")  : Promise<any> => {
    let form = new FormData();

    files.forEach(file => {
        form.append(filename, file);
    });

    if (extra) {
        Object.keys(extra).forEach(key => {
            form.append(key, extra[key]);
        });
    }


    let responseJson;

    let maxRetries = 1;
    let retries = 0;

    while (retries < maxRetries) 
    {
        const post = await fetch(`${process.env.REACT_APP_EXTERNAL_API_URL}${url}`, {
            method: "POST",
            headers: {  },
            body: form,
        }).then(async (response) => {
            //console.log(response);
            if(response.ok){
                responseJson = await response.json();
                retries = maxRetries;
            }
            else
            {
                throw new Error("Error: "+response.status);
            }
        }).catch(async (error) => { 
            // Error handling here
            console.error(`Error making POST request: ${error}`);

            // Implement a delay here if needed.

            if (retries === maxRetries) {
            //throw new Error(`Max retries (${maxRetries}) reached. Unable to complete the request.`);
            }
            await delay(2000);
        });
        // Increment the retry count
        retries++;
    }

    return responseJson;
}



export const httpPut = async (url: string, body: any) : Promise<any> => {

    let responseJson;

    let maxRetries = 1;
    let retries = 0;

    while (retries < maxRetries) {

    const post = await fetch(`${process.env.REACT_APP_EXTERNAL_API_URL}${url}`, {
        method: "PUT",
        headers: {
            ...header,
           
        },
        body: JSON.stringify(body),
    }).then(async (response) => {
        if(response.ok){
            responseJson = await response.json();
            retries = maxRetries;
        }
        else
        {
            throw new Error("Error: "+response.status);
        }
    }).catch(async (error) => { 
        // Error handling here
        console.error(`Error making POST request: ${error}`);

        // Implement a delay here if needed.

        if (retries === maxRetries) {
        //throw new Error(`Max retries (${maxRetries}) reached. Unable to complete the request.`);
        }
        await delay(2000);
    });
    // Increment the retry count
    retries++;;

    }   

    return responseJson;
}
export const httpRawGet = async (url: string, session: any = null) : Promise<any> => {

    let responseText;

    let maxRetries = 1;
    let retries = 0;

    while (retries < maxRetries) {

        try{
            const get = await fetch(`${process.env.REACT_APP_EXTERNAL_API_URL}${url}`, {
                method: "GET",
                headers: {
                    
                },
            }).then(async (response) => {
                if(response.ok){
                    responseText = await response.text();
                    retries = maxRetries;
                }
                else
                {
                    throw new Error("Error: "+response.status);
                }               

            }).catch(async (error) => { 
                // Error handling here
                console.error(`Error making GET request: ${error}`);

                // Implement a delay here if needed.

                if (retries === maxRetries) {
                //throw new Error(`Max retries (${maxRetries}) reached. Unable to complete the request.`);
                }
                await delay(2000);
            });
        }
        catch(error)
        {
            console.error(`Error making GET request: ${error}`);

        }
        // Increment the retry count
        retries++;;

    }

    return responseText;
}

export const httpGet = async (url: string, session: any = null) : Promise<any> => {

    let responseJson;

    let maxRetries = 1;
    let retries = 0;

    while (retries < maxRetries) {
        try
        {
            const get = await fetch(`${process.env.REACT_APP_EXTERNAL_API_URL}${url}`, {
                method: "GET",
                headers: {
                    ...header,
                },
            }).then(async (response) => {
                if(response.ok){
                    responseJson = await response.json();
                    retries = maxRetries;
                }
                else
                {
                    throw new Error("Error: "+response.status);
                }                   
            }).catch(async (error) => { 
                // Error handling here
                console.error(`Error making POST request: ${error}`);

                // Implement a delay here if needed.

                if (retries === maxRetries) {
                //throw new Error(`Max retries (${maxRetries}) reached. Unable to complete the request.`);
                }
                await delay(2000);
            });

        }
        catch(error)
        {
            console.error(`Error making GET request: ${error}`);

        }
       // //console.log(responseJson);
        // Increment the retry count
        retries++;;
    }   


    return responseJson;
}



export const getRequest = async (url: string, header = {}) : Promise<any> =>{
    let responseJson;

    let maxRetries = 1;
    let retries = 0;

    while (retries < maxRetries) {

        try
        {
            const get = await fetch(`${url}`, {
                method: "GET",
                headers: {
                    ...header,
                },
            }).then(async (response) => {
                if(response.ok){
                    responseJson = await response.json();
                    retries = maxRetries;
                }
                else
                {
                    throw new Error("Error: "+response.status);
                }           

            }).catch(async (error) => { 
                // Error handling here
                console.error(`Error making POST request: ${error}`);
        
                // Implement a delay here if needed.
        
                if (retries === maxRetries) {
                //throw new Error(`Max retries (${maxRetries}) reached. Unable to complete the request.`);
                }
                await delay(2000);
            });

        }
        catch(error)
        {
            console.error(`Error making GET request: ${error}`);
        
        }

        // Increment the retry count
        retries++;;

    }   

    return responseJson;
}




